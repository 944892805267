
.social-media-links {
    padding: 2rem 0;
    display: flex;
    gap: 30px;
    justify-content: flex-start;
}

.social-media-links a {
    color: #fff;
    font-size: 1.2em;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s, transform 0.3s;
    padding: 0 15px;
    border-radius: 20px;
    background-color: #333;
}

.social-media-links a:hover {
    color: #b28ce0;
    transform: scale(1.2);
}

.icon-text{
    margin-left: 0.5rem;
}



@media (max-width: 480px) {
    .social-media-links {
        flex-direction: column;
        gap: 10px;
        padding-top: 1.5rem;
    }

    .social-media-links a {
        justify-content: flex-start; 
        padding: 10px 20px; 
        font-size: 0.9em; 
    }

    .icon-text {
        margin-left: 0.5rem;    
    }
}