.header {
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* .logo-container { 
  position: fixed;
  left: 0;
  padding: 1rem;
  z-index: 1000000;
  cursor: pointer;
}

.logo {
  height: 50px;
  width: auto;
} */

nav button {
  font-family: inherit;
  text-decoration: none;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding:0rem;
  margin: 0rem;
}

nav button:hover {
  color: white;
}

.nav-item-ticket {
  width: 110%;
  border: 4px solid #b28ce0;
  background-color: #b28ce0;
  border-radius: 6px;
  margin-top: -15px;
}

nav ul {
  color: white;
  list-style-type: none;
  display: flex;
  justify-content: center;
  padding: 0;
}

nav li {
  font-size: 1.3rem;
  padding: 0 10px;
}

nav li:hover {
  cursor: pointer;
}

.nav-item-edicoes {
  position: relative;
}

.nav-item-informacoes {
  position: relative;
}

.submenu {
  position: absolute;
  left: 0;
  display: none;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
}

.submenu ul {
  padding: 10px;
  border-radius: 4px;
}

.submenu li {
  padding: 10px 10px;
  display: block;
}

.submenu li:hover {
  cursor: pointer;
  color: #b28ce0;
}

.submenu a {
  text-decoration: none;
  color: inherit;
}

.submenu a:hover {
  color: #b28ce0;
}

.submenu a:visited {
  color: inherit;
}

@media (min-width: 769px) {

  .submenu,
  .submenu ul,
  .submenu li {
    background-color: black;
    margin-left: -0.5rem;
  }

  .nav-item-edicoes:hover .submenu {
    display: block;
  }

  .nav-item-informacoes:hover .submenu {
    display: block;
  }
}

@media (max-width: 768px) {

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: black;
    position: fixed;
    width: 100%;
    z-index: 1000000;
  }

  .menu-button {
    position: fixed;
    left: 50%;
    transform: translate(-50%);
    z-index: 1000000;
  }

  .submenu,
  .submenu ul,
  .submenu li {
    font-size: 1rem;
  }

  .submenu-visible .submenu {
    display: block;
    position: static;
  }

  nav button{
    padding: 0.2rem;
  }
}

@media (max-width: 377px) {

  nav button,
  nav li {
    font-size: 0.8rem;
    padding: 0 0.1rem;
  }

  .logo-container {
    height: 1rem;
    width: auto;
  }
}

@media (max-width: 768px) {

  nav button,
  nav li {
    font-size: 1.3rem;
  }

  nav {
    padding-left: 10%;
  }

  .logo {
    height: 45px;
    width: auto;
  }
}


.menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
}

.nav {
  transition: transform 0.3s ease-in-out;
}

.nav.open {
  transform: translateX(0);
}

@media (max-width: 767px) {
  .menu-button {
    display: block;
    position: fixed;
  }

  .nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 80%;
    background-color: rgba(0, 0, 0, 0.85);
    transform: translateX(-100%);
  }





  .nav ul {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .nav li {
    margin: 1rem 0;
  }
}

.close-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

.nav.open .close-button {
  display: block;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

@media (min-width: 769px) {
  .nav {
    position: relative;
    transform: none;
    height: auto;
    width: auto;
    background-color: transparent;
  }

  .nav ul {
    flex-direction: row;
    height: auto;
  }

  .nav li {
    margin: 0 0.1rem;
  }
}

.nav-link {
  position: relative;
}

.nav-link::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  /* Escolhe a cor que preferires para o sublinhado */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.nav-item-ticket {
  position: relative;
}

.nav-item-ticket::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 5px;
  bottom: 0;
  left: 0;
  background-color: white;
  /* Escolhe a cor que preferires para o sublinhado */
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.nav-item-ticket:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  background-color: antiquewhite;
}