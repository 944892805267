body {
  margin: 0;
  font-family: 'Gruppo', cursive;
  font-family: 'Inconsolata', monospace;
  font-family: 'Nanum Myeongjo', serif;
  font-family: 'Playfair Display', serif;
  font-family: 'Teko', sans-serif;
  background-color: black;
  overflow-x: hidden; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Estilos globais */

html {
  font-size: 16px;
}

/* Media Queries */

/* Para dispositivos com tela maior que 1200px */
@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

/* Para dispositivos com tela entre 768px e 1199px */
@media (min-width: 768px) and (max-width: 1199px) {
  html {
    font-size: 16px;
  }
}

/* Para dispositivos com tela menor que 767px */
@media (max-width: 767px) {
  html {
    font-size: 14px;
  }
}
