/* Set the image as a background covering the entire viewport */
.landing-page-image-container {
  display: grid;
  grid-template-rows: 5fr 5fr 1fr 1fr;
  width: 100%;
  height: 92vh;
}

/* landing page FEN logo */
.fen-logo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.fen-logo-div img {
  height: 200px;
}

.desktop-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.desktop-text {
  align-self: center;
  padding: 0rem 1rem;
  font-size: 60px;
  color: #ffff;
  text-align: end;
}

/* landing page text */
.text-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-div {
  display: none;
}

.sub-title-div {
  align-self: center;
  padding: 0rem 1rem;
  font-size: 60px;
  color: #ffff;
}
.dates-div {
  align-self: center;
  padding: 0rem 1rem;
  font-size: 60px;
  color: #ffff;
}

/* tickets */
.tickets-button-div {
  /* display: none; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.tickets-button {
  font-family: 'CustomFont';
  padding: 0.5rem 3rem;
  border: 0px;
  border-radius: 6px;
  transition: transform 0.3s ease-in-out;
  font-size: 30px;
  color: white;
  font-weight: bold;
  background-color: #b28ce0;
  position: relative;
}

.tickets-button:hover {
  transform: scale(1.05);
  cursor: pointer;
}

/* down arrow */
.down-arrow-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffff;
  margin: 1rem;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
}

.down-arrow-icon {
  transition: transform 0.3s ease-in-out;
}

.down-arrow-icon:hover {
  transform: scale(1.05);
  cursor: pointer;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

/* ================= BIG SCREEN (1920x1080) ================= */
@media (min-width: 1920px) {
  /* landing page FEN logo */
  .fen-logo-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 6rem;
  }

  .fen-logo-div img {
    height: 500px;
  }
}

/* =================== MOBILE HORIZONTAL ==================== */
@media (max-width: 950px) {
  /* container div for information */
  .landing-page-information {
    width: 100%;
    height: 100vh;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    display: grid;
    grid-template-rows: 1fr 2fr 3fr 1fr;
  }

  /* landing page FEN logo */
  .fen-logo-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
  }

  .fen-logo-div img {
    height: 50px;
  }

  /* landing page text */
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2rem;
  }

  .title-div {
    display: block;
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 20px;
    color: white;
  }

  .sub-title-div {
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 15px;
    color: white;
  }
  .dates-div {
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 15px;
    color: white;
  }

  /* tickets */
  .tickets-button-div {
    /* display: none; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    text-align: start;
  }
}

/* ========================= MOBILE ========================= */
@media (max-width: 767px) {
  .landing-page-image-container {
    display: grid;
    grid-template-rows: 1fr 4fr 1fr;
  }

  /* container div for information */
  .landing-page-information {
    width: 100%;
    height: 100vh;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 999;
    display: grid;
    grid-template-rows: 1fr 2fr 3fr 1fr;
  }

  /* landing page FEN logo */
  .fen-logo-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 2rem;
    height: 10px;
  }

  .fen-logo-div img {
    height: 0px;
  }

  /* landing page text */
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 2rem;
  }

  .title-div {
    display: block;
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 60px;
    color: white;
  }

  .sub-title-div {
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 20px;
    color: white;
  }
  .dates-div {
    align-self: flex-start;
    padding: 0rem 1rem;
    font-size: 20px;
    color: white;
  }

  /* tickets */
  .tickets-button-div {
    /* display: none; */
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 6rem;
    margin-left: 1rem;
    text-align: start;
  }
}

