.button-wrapper {
  margin-top: 1rem;
  border: solid white 2px;
  border-radius: 1.625rem;
  transition: transform 0.5s ease, opacity 0.5s ease;
}



.button-wrapper .button-news {
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  width: 10rem;
  height: auto;
}

.button-news,
.button-circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #ffff;
  border-radius: 1.625rem;
}

.button-news .button-circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.button-news .button-circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.button-news .button-circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #282936;
  border-right: 0.125rem solid #282936;
  transform: rotate(45deg);
}

.button-news .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: white; 
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
  
}

.button-wrapper:hover .button-news .button-circle {
  width: 100%; 
}

.button-wrapper:hover .button-news .button-circle .icon.arrow {
  background: #282936;
  transform: translate(1rem, 0); 
}

.button-wrapper:hover .button-news .button-text {
  color: #282936; 
}
