.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .video-container iframe {
    width: 900px;
    height: 500px;
    border: none;
  }
  
  @media (max-width: 768px) {
    .video-container iframe {
      width: 100%;
      height: 315px;
    }
  }
  