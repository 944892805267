.news-container {
  height: 50vh;
  display: flex;
  flex-direction: row;
}

.news-item {
  background-size: cover;
  background-position: center;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 0; /* Define uma largura inicial igual para todos os itens antes do crescimento */
  flex-shrink: 1; /* Permite que os itens encolham se necessário, mantendo a proporção */
  overflow: hidden; /* Garante que o background não ultrapasse os limites do item */
  font-size: 0.8rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.news-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.news-item:hover .news-item-bg {
  transform: scale(1.1);
  filter: brightness(0.5);
}

/* Certifica-se de que o conteúdo sobreposto ao background tenha posição relativa para ficar acima do background */
.news-item > .news-item-content {
  position: relative;
}

.news-item-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  width:100%;
  height: 100%;
  padding-bottom: 2rem;
}

.news-title {
  transition: transform 0.5s ease;
}

.news-item:hover .news-title {
  transform: translateY(-20px);
}

.news-item-content-button-desktop {
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.news-item:hover .news-item-content-button-desktop {
  transform: translateY(-20px);
  opacity: 1;
}

.news-item-content-button-mobile {
  transition: transform 0.5s ease;
}

.news-item:hover  .news-item-content-button-mobile {
  transform: translateY(-20px);
}


/* ========================= MOBILE HORIZONTAL ========================= */
@media (max-width: 950px) {
  .news-container {
    height: 150vh;
    flex-direction: column;
  }
}

/* ========================= MOBILE ========================= */
@media (max-width: 768px) {
  .news-container {
    height: 150vh;
    flex-direction: column;
  }
}
