.lineup-component {
    padding: 3rem 2rem;
}

.date {
    display: flex;
    color: white;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.date:hover {
    font-size: 40px;
    color: #b28ce0; 
    text-shadow: 0px 0px 8px rgba(67, 79, 249, 0.8);
}

.date::after {
    content: '';
    position: absolute;
    width: 33.3%;
    height: 3px;
    background-color: white;
    bottom: -10px;
    left: 33.3%;
    transition: all 0.3s ease-in-out;
}

.date:hover::after {
    left: 25%;
    width: 50%;
}


.lineup-container{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

}

.lineup-item {
    background-size: cover;
    background-position: center;
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(31% - 20px);
    height: 250px;
    margin: 10px 0;
    overflow: hidden;
    font-size: 1rem;
    padding: 1rem;
    cursor: pointer;
    border-radius: 10px;
}


.lineup-item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease, filter 0.5s ease;
}

.lineup-item:hover .lineup-item-bg {
    transform: scale(1.1);
    filter: brightness(0.6);
}

.lineup-item-content {
    position: relative;
    text-align: center;
    z-index: 1;
    padding: 10px;
}

.lineup-artist-name {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    transition: color 0.3s ease, text-shadow 0.3s ease;
}

.lineup-item:hover .lineup-artist-name {
    color: #b28ce0;
    text-shadow: 0px 0px 8px rgba(67, 79, 249, 0.8);
}


.lineup-artist-details {
    font-size: 1rem;
    margin-top: 0.5rem;
}

/* ========================= MOBILE HORIZONTAL ========================= */
@media (max-width: 950px) {


    .lineup-container {
        justify-content: flex-start;
        /* Mantém os itens alinhados à esquerda */
    }

    .lineup-item {
        width: calc(45% - 20px);
        /* Cada item ocupa metade da linha menos o espaço do gap */
        margin: 10px 0;
        /* Espaçamento vertical */
        height: 200px;
        /* Ajuste a altura para dispositivos menores */
    }
}

/* ========================= MOBILE ========================= */
@media (max-width: 768px) {

    .lineup-component {
        padding-inline: 2rem;
        padding-top: 10rem;

    }

    .lineup-container {
        justify-content: flex-start;
        /* Mantém os itens alinhados à esquerda */
    }

    .lineup-item {
        width: 100%;
        /* Cada item ocupa toda a largura em dispositivos móveis */
        margin: 10px 0;
        /* Espaçamento vertical */
        height: 200px;
        /* Ajuste a altura para dispositivos menores */
    }
}