.header-landing-page-container {
  width: 100%;
  height: 100vh;
  background-image: url("https://i.imgur.com/lonbLKS.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Style for the image (optional) */
.landing-page-image-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* ========================= MOBILE HORIZONTAL ========================= */
@media (max-width: 950px) {
    /* Set the image as a background covering the entire viewport */
    .header-landing-page-container {
      width: 100%;
      height: 100vh;
      background-image: url("https://i.imgur.com/lonbLKS.jpeg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: grid;
      grid-template-rows: 1fr 4fr 1fr;;
    }
}

/* ========================= MOBILE ========================= */
@media (max-width: 767px) {
    /* Set the image as a background covering the entire viewport */
    .header-landing-page-container {
      width: 100%;
      height: 100vh;
      background-image: url("https://i.imgur.com/SpSk6Bj.jpeg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
}