/* In your CSS file (e.g., App.css) */
@font-face {
  font-family: 'CustomFont'; /* Provide a name for your font */
  src: url('./fonts/Heavitas.ttf') format('truetype'); /* Path to your font file */
}

body {
  font-family: 'CustomFont', sans-serif; /* Apply the custom font to the entire project */
}

.App {
  background-position: center;
  background-size: cover;
  text-align: center;
  width: 100%;
}

.page-container {
  max-width: 100%;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.sections-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.section {
  min-height: 100vh;
}



.App-link {
  color: #b28ce0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.background-container {
  min-height: 100vh;
  background-size: cover;
  background-attachment: fixed;
}



@media (max-width: 768px) {
  .background-container {
    background-position: center center;
    background-size: auto 100%;
    background-attachment: scroll;
  }

  .section{
    margin-top: -55px;
    padding-top: 55px;
  }

}
