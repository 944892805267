.lineup-header-background {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
}

/* .lineup-header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
} */

.lineup-header-container {
  width: 100%;
  height: 80vh;
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.lineup-header-festival-title {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6rem;
  font-size: 42px;
  color: #fff;
}

.lineup-header-lineup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4rem;
  font-size: 32px;
}

.festival-lineup {
  z-index: 1;
  height: 60vh;
  animation: bouncing-animation 2s ease infinite;
}

@keyframes bouncing-animation {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* ========================= SVG SHAPES DIVIDER ========================= */
.custom-shape-divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider svg {
  position: relative;
  display: block;
  width: calc(199% + 1.3px);
  height: 448px;
  transform: rotateY(180deg);
}

.custom-shape-divider .shape-fill {
  fill: #000000;
}

/* ========================= MOBILE ========================= */
@media (max-width: 767px) {
  .lineup-header-background {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 70vh;
    width: 100%;
  }

  .festival-lineup {
    display: none;
  }

  .custom-shape-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider svg {
    position: relative;
    display: block;
    width: calc(199% + 1.3px);
    height: 200px;
    transform: rotateY(180deg);
  }

  .custom-shape-divider .shape-fill {
    fill: #000000;
  }
}
