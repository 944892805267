/* Footer.css */
.footer {
  padding: 2rem 3rem;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 3px solid white;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo Section */
.logo-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.fen-logo {
  width: 100px;
  height: auto;
}

/* Footer Text */
.footer-text {
  flex: 1;
  font-size: 1rem;
  text-align: center;
  padding-top: 2rem;
}

.social-text {
  display: flex;
  flex-direction: column;
  align-items: self-start;
}

/* Social Media Icons */
.social-media {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
}

.social-icon {
  font-size: 2rem;
  color: white;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.social-icon:hover {
  color: #b28ce0;
  transform: scale(1.2);
}


/* ========================= MOBILE ========================= */
@media (max-width: 767px) {
  .footer-content {
    flex-direction: column;
    gap: 1rem;
  }

  .logo-container,
  .footer-text,
  .social-media {
    justify-content: center;
  }

  .social-text {
    align-items: center;
  }

  .fen-logo {
    width: 60px;
  }

  .footer-text {
    font-size: 0.875rem;
  }

  .social-icon {
    font-size: 3rem;
  }
}

/* ========================= SMALL MOBILE ========================= */
@media (max-width: 360px) {
  .fen-logo {
    width: 100px;
  }

  .footer-text {
    font-size: 0.75rem;
  }

  .social-icon {
    font-size: 1.25rem;
  }
}
