.artist-container {
  display: flex;
  flex-direction: row;
  background-color: #000;
  color: #fff;
  padding: 20px;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  padding-bottom: 4rem;
}

.artist-image {
  flex: 1;
  max-width: 40%;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.artist-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.artist-image img:hover {
  transform: scale(1.1);
}

.artist-info {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.artist-info h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.artist-info h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #b28ce0;
}

.artist-info .concert-time {
  font-size: 1.2em;
  margin-bottom: 20px;
  font-weight: bold;
  color: #ccc;
}

.artist-info p {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.6;
}

.artist-links {
  margin-top: 20px;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}

.artist-links a {
  color: #fff;
  font-size: 2em;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s, transform 0.3s;
  padding: 10px;
  border-radius: 50%;
  background-color: #333;
}

.artist-links a:hover {
  color: #b28ce0;
  transform: scale(1.2);
}

/* Mobile */
@media (max-width: 768px) {
  .artist-container {
    flex-direction: column;
    padding: 10px;
    text-align: center;
    padding-top: 3rem;
  }

  .artist-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .artist-info {
    margin-left: 0;
    padding: 0;
  }

  .artist-info h1 {
    font-size: 2em;
  }

  .artist-info h2 {
    font-size: 1.8em;
  }

  .artist-info .concert-time {
    font-size: 1em;
    margin-bottom: 15px;
  }

  .artist-info p {
    font-size: 1em;
  }

  .artist-links {
    justify-content: center;
  }

  .artist-links a {
    font-size: 1.5em;
  }
}