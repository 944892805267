.scrolltoTop-button{
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  background: none; 
  border: none; 
  padding: 0; 
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top-button:hover {
  opacity: 0.8;
}

.scrolltoTop-icon {
  color: #b28ce0;
  font-size: 2.5rem;
}

.scrolltoTop-button:hover .scrolltoTop-icon {
  color: #9f72d1;
}


@media (max-width: 768px) {
  .scrolltoTop-button {
      display: none;
  }
}