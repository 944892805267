.partner-footer {
    background-color: black;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    /* color: #fff; */
    /* align-items: center;
    flex-wrap: wrap; */
    /* border: 1px solid white;  */

}

.left-side {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.left-side h1 {
    font-size: 24px;
    /* margin: 0; */
    padding: 0;
    color: #ffffff; /* Branco puro para contraste */
    font-weight: bold; /* Deixa o texto mais robusto */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Sombra sutil para definição */
    letter-spacing: 1px; /* Um pouco de espaçamento entre as letras */
}

.right-side {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.partner-logo {
    margin: 10px;
    max-width: 100px;
    max-height: 50px;
    object-fit: contain;
}

@media screen and (max-width: 767px) {
    .footer {
        flex-direction: column;
        text-align: center;
    }

    .left-side {
        justify-content: center;
        margin-bottom: 20px;
    }

    .right-side {
        justify-content: center;
    }

    .partner-logo {
        max-width: 80px;
        max-height: 40px;
    }
}
