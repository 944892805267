.festival-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.festival-description{
    padding: 2rem;
}


@media (max-width: 768px) {
    .festival-container {
    padding-top: 8rem;
    }
  }
  